import { get, post } from '.';

const apiURL = process.env.REACT_APP_SUPER_ADMIN_API_URL;

export const getEndpoints = () => {
  return fetch(`${apiURL}/api/endpoints`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res;
    })
    .then((res) => res.json());
};

export const getCountries = () => get('as://api/api/countries');

export const getRandomPassword = () =>
  get('as://api/registration/getRandomPassword');

export const runTask = (taskName, params) => {
  return post(`${process.env.REACT_APP_HUB_SERVER_URL}/tasks/run`, {
    params: {
      name: taskName,
      params,
    },
  });
};
