"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyCountryIdToCodeMap = void 0;
const enums_1 = require("../../enums");
exports.CurrencyCountryIdToCodeMap = {
    [enums_1.Country.CANADA]: enums_1.CurrencyCode.CAD,
    [enums_1.Country.USA]: enums_1.CurrencyCode.USD,
    [enums_1.Country.UK]: enums_1.CurrencyCode.GBP,
    [enums_1.Country.AUS]: enums_1.CurrencyCode.AUD,
};
