import React from 'react';
import { NumericFormat } from 'react-number-format';
import { CurrencyDBIdToCodeMap } from '@applauz/constants';

const getNumericFormatComponent = (rowData) => (
  <NumericFormat
    value={rowData.total}
    displayType={'text'}
    thousandSeparator
    prefix={`${CurrencyDBIdToCodeMap[rowData.currencyId] || '?'} $`}
    decimalScale={2}
    fixedDecimalScale
  />
);

export default getNumericFormatComponent;
