import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import SubmitButton from './SubmitButton';
import GridDivider from './GridDivider';

function Footer({
  subTotal,
  updateType,
  formatPrice,
  isApplyNow,
  customDiscounts,
  canSubmit,
  disabledSubmitButton,
  onUpdateNextPlan,
  onQuoteGenerate,
  currencyCode,
}) {
  return (
    <>
      <Grid item xs={8} />
      <Grid item xs={2}>
        <Typography variant="body1" align="right">
          Subtotal:{' '}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1" align="right">
          <strong style={subTotal < 0 ? { color: 'red' } : undefined}>
            {updateType === 'same'
              ? `${currencyCode} 0.00`
              : formatPrice(subTotal, currencyCode)}
          </strong>
        </Typography>
      </Grid>

      <GridDivider />

      <Grid item xs={6}>
        <Button variant="contained" onClick={customDiscounts.add}>
          Add discount
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Box display="flex" justifyContent="flex-end">
          <Box alignItems="center">
            {updateType === 'downgrade' && '*downgrade '}
            {updateType === 'same' && '*same package is selected. '}
            {typeof onUpdateNextPlan === 'function' && (
              <SubmitButton
                label="Set Upcoming Licences"
                disabled={!canSubmit || disabledSubmitButton}
                onClick={onUpdateNextPlan}
              />
            )}

            {isApplyNow && (
              <SubmitButton
                label="Generate Quote"
                disabled={!canSubmit}
                onClick={onQuoteGenerate}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </>
  );
}

export default Footer;
