"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Currencies = void 0;
const enums_1 = require("../../enums");
exports.Currencies = {
    byCountryId: {
        [enums_1.Country.CANADA]: enums_1.CurrencyCode.CAD,
        [enums_1.Country.USA]: enums_1.CurrencyCode.USD,
        [enums_1.Country.UK]: enums_1.CurrencyCode.GBP,
        [enums_1.Country.AUS]: enums_1.CurrencyCode.AUD,
    },
    byDBId: {
        [enums_1.CurrencyDBId.CAD]: enums_1.CurrencyCode.CAD,
        [enums_1.CurrencyDBId.USD]: enums_1.CurrencyCode.USD,
        [enums_1.CurrencyDBId.GBP]: enums_1.CurrencyCode.GBP,
        [enums_1.CurrencyDBId.AUD]: enums_1.CurrencyCode.AUD,
    },
    get Ids() {
        return Object.values(enums_1.CurrencyDBId);
    },
};
