"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpotGiftDefaultMessage = void 0;
const locales_1 = require("../../locales");
// Remove the indentation spaces from the beginning of the lines
function removeIndentation(text) {
    return text.replace(/\n */g, '\n').trim();
}
exports.SpotGiftDefaultMessage = {
    en_ca: removeIndentation(locales_1.DefaultMessageLocales.Customizations.en[`default-messages/customizations/spot-gift`]),
    fr_ca: removeIndentation(locales_1.DefaultMessageLocales.Customizations.fr[`default-messages/customizations/spot-gift`]),
};
