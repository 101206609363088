"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomizationsDefaultMessages = void 0;
const locales_1 = require("../../locales");
const enums_1 = require("../../enums");
// Remove the indentation spaces from the beginning of the lines
function removeIndentation(text) {
    return text.replace(/\n */g, '\n').trim();
}
const defaultValues = {
    [enums_1.CustomizationType.BIRTHDAY_POST]: {
        en: removeIndentation(locales_1.DefaultMessageLocales.Customizations.en[`default-messages/customizations/${enums_1.CustomizationType.BIRTHDAY_POST}`]),
        fr: removeIndentation(locales_1.DefaultMessageLocales.Customizations.fr[`default-messages/customizations/${enums_1.CustomizationType.BIRTHDAY_POST}`]),
    },
    [enums_1.CustomizationType.BIRTHDAY_EMAIL]: {
        en: removeIndentation(locales_1.DefaultMessageLocales.Customizations.en[`default-messages/customizations/${enums_1.CustomizationType.BIRTHDAY_EMAIL}`]),
        fr: removeIndentation(locales_1.DefaultMessageLocales.Customizations.fr[`default-messages/customizations/${enums_1.CustomizationType.BIRTHDAY_EMAIL}`]),
    },
    [enums_1.CustomizationType.ANNIVERSARY_POST]: {
        en: removeIndentation(locales_1.DefaultMessageLocales.Customizations.en[`default-messages/customizations/${enums_1.CustomizationType.ANNIVERSARY_POST}`]),
        fr: removeIndentation(locales_1.DefaultMessageLocales.Customizations.fr[`default-messages/customizations/${enums_1.CustomizationType.ANNIVERSARY_POST}`]),
    },
    [enums_1.CustomizationType.ANNIVERSARY_EMAIL_REGULAR]: {
        en: removeIndentation(locales_1.DefaultMessageLocales.Customizations.en[`default-messages/customizations/${enums_1.CustomizationType.ANNIVERSARY_EMAIL_REGULAR}`]),
        fr: removeIndentation(locales_1.DefaultMessageLocales.Customizations.fr[`default-messages/customizations/${enums_1.CustomizationType.ANNIVERSARY_EMAIL_REGULAR}`]),
    },
    [enums_1.CustomizationType.ANNIVERSARY_EMAIL_MILESTONE]: {
        en: removeIndentation(locales_1.DefaultMessageLocales.Customizations.en[`default-messages/customizations/${enums_1.CustomizationType.ANNIVERSARY_EMAIL_MILESTONE}`]),
        fr: removeIndentation(locales_1.DefaultMessageLocales.Customizations.fr[`default-messages/customizations/${enums_1.CustomizationType.ANNIVERSARY_EMAIL_MILESTONE}`]),
    },
    [enums_1.CustomizationType.GOALS_POST]: {
        en: removeIndentation(locales_1.DefaultMessageLocales.Customizations.en[`default-messages/customizations/${enums_1.CustomizationType.GOALS_POST}`]),
        fr: removeIndentation(locales_1.DefaultMessageLocales.Customizations.fr[`default-messages/customizations/${enums_1.CustomizationType.GOALS_POST}`]),
    },
    [enums_1.CustomizationType.GOALS_EMAIL]: {
        en: removeIndentation(locales_1.DefaultMessageLocales.Customizations.en[`default-messages/customizations/${enums_1.CustomizationType.GOALS_EMAIL}`]),
        fr: removeIndentation(locales_1.DefaultMessageLocales.Customizations.fr[`default-messages/customizations/${enums_1.CustomizationType.GOALS_EMAIL}`]),
    },
    [enums_1.CustomizationType.WELCOME_EMAIL]: {
        en: removeIndentation(locales_1.DefaultMessageLocales.Customizations.en[`default-messages/customizations/${enums_1.CustomizationType.WELCOME_EMAIL}`]),
        fr: removeIndentation(locales_1.DefaultMessageLocales.Customizations.fr[`default-messages/customizations/${enums_1.CustomizationType.WELCOME_EMAIL}`]),
    },
    [enums_1.CustomizationType.GOALS_PARTIALLY_COMPLETED_POST]: {
        en: removeIndentation(locales_1.DefaultMessageLocales.Customizations.en[`default-messages/customizations/${enums_1.CustomizationType.GOALS_PARTIALLY_COMPLETED_POST}`]),
        fr: removeIndentation(locales_1.DefaultMessageLocales.Customizations.fr[`default-messages/customizations/${enums_1.CustomizationType.GOALS_PARTIALLY_COMPLETED_POST}`]),
    },
    [enums_1.CustomizationType.GOALS_PARTIALLY_COMPLETED_EMAIL]: {
        en: removeIndentation(locales_1.DefaultMessageLocales.Customizations.en[`default-messages/customizations/${enums_1.CustomizationType.GOALS_PARTIALLY_COMPLETED_EMAIL}`]),
        fr: removeIndentation(locales_1.DefaultMessageLocales.Customizations.fr[`default-messages/customizations/${enums_1.CustomizationType.GOALS_PARTIALLY_COMPLETED_EMAIL}`]),
    },
    [enums_1.CustomizationType.SERVICE_AWARD_MESSAGE]: {
        en: removeIndentation(locales_1.DefaultMessageLocales.Customizations.en[`default-messages/customizations/${enums_1.CustomizationType.SERVICE_AWARD_MESSAGE}`]),
        fr: removeIndentation(locales_1.DefaultMessageLocales.Customizations.fr[`default-messages/customizations/${enums_1.CustomizationType.SERVICE_AWARD_MESSAGE}`]),
    },
    [enums_1.CustomizationType.RETIREMENT_MILESTONE_MESSAGE]: {
        en: removeIndentation(locales_1.DefaultMessageLocales.Customizations.en[`default-messages/customizations/${enums_1.CustomizationType.RETIREMENT_MILESTONE_MESSAGE}`]),
        fr: removeIndentation(locales_1.DefaultMessageLocales.Customizations.fr[`default-messages/customizations/${enums_1.CustomizationType.RETIREMENT_MILESTONE_MESSAGE}`]),
    },
};
function getMessage(type) {
    return defaultValues[type];
}
function isDefault(type, lang, msg) {
    return defaultValues[type][lang] === removeIndentation(msg);
}
exports.CustomizationsDefaultMessages = {
    ...defaultValues,
    getMessage,
    isDefault,
};
