/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import moment from 'moment';
import getNumericFormatComponent from './getNumericFormatComponent';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import DoneIcon from '@material-ui/icons/Done';
import PostAddIcon from '@material-ui/icons/PostAdd';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Tooltip from '@material-ui/core/Tooltip';

import useActions from './useActions';

export default function useColumns() {
  const actions = useActions();

  return React.useMemo(() => {
    const columns = [
      {
        headerName: 'Date Invoiced',
        field: 'tsInserted',
        defaultSort: 'desc',
        flex: 1,
        valueGetter: ({ row }) =>
          moment(row.tsInserted).local().format('YYYY-MM-DD HH:mm'),
      },
      {
        headerName: 'Name',
        field: 'organizationName',
        flex: 1,
      },
      {
        headerName: 'Type',
        field: 'type',
        flex: 1,
      },
      {
        headerName: 'Total',
        field: 'total',
        renderCell: ({ row }) => getNumericFormatComponent(row),
        flex: 1,
        type: 'number',
      },
      {
        headerName: 'Invoice Number',
        field: 'xeroInvoiceNumber',
        flex: 1,
      },
      {
        headerName: 'Status',
        field: 'status',
        flex: 1,
      },
      {
        headerName: 'Payment Type',
        field: 'paymentType',
        flex: 1,
      },
      {
        headerName: 'Subtotal',
        field: 'subtotal',
      },
      {
        headerName: 'Taxes',
        field: 'taxes',
        renderCell: ({ row }) =>
          row.taxes ? Number(row.taxes).toFixed(2) : '',
      },
      {
        headerName: 'Actions',
        field: 'invoiceId',
        flex: 1,
        renderCell: ({ row }) => {
          const buttons = [];

          buttons.push(
            <Tooltip title="Download">
              <IconButton>
                <GetAppIcon onClick={() => actions.onDownload(row.invoiceId)} />
              </IconButton>
            </Tooltip>
          );

          if (row.status === 'Invoiced') {
            buttons.push(
              <Tooltip title="Set Paid">
                <IconButton>
                  <DoneIcon
                    onClick={() =>
                      actions.onSetPaid(
                        row.invoiceId,
                        row.organizationName,
                        row.type,
                        row.total
                      )
                    }
                  />
                </IconButton>
              </Tooltip>
            );
          }

          if (row.status === 'Paid') {
            buttons.push(
              <Tooltip title="Complete Invoice">
                <IconButton>
                  <PostAddIcon
                    onClick={() =>
                      actions.onCompleteInvoice(
                        row.invoiceId,
                        row.organizationName,
                        row.type,
                        row.total,
                        row.callbackIsV1
                      )
                    }
                  />
                </IconButton>
              </Tooltip>
            );
          }

          if (row.payments.length) {
            buttons.push(
              <Tooltip title="Show payments">
                <IconButton>
                  <LocalAtmIcon
                    onClick={() => alert(JSON.stringify(row.payments, null, 2))}
                  />
                </IconButton>
              </Tooltip>
            );
          }

          if (row.status === 'Quote') {
            buttons.push(
              <Tooltip title="Get Payment Link">
                <IconButton>
                  <ShoppingCartIcon
                    onClick={() =>
                      prompt(
                        'Copy the payment link bellow:\n\n !! Must be logged in as the organization to pay the invoice !!',
                        `${process.env.REACT_APP_HUB_CLIENT_URL}/invoices?invoiceId=${row.invoiceId}`
                      )
                    }
                  />
                </IconButton>
              </Tooltip>
            );
          }
          return buttons;
        },
      },
    ];
    return columns;
  }, [actions]);
}
