import React from 'react';
import { getPDF, callCallbackURL, setPaid } from 'helpers/api/payments';
import { completeWiretransferPurchase } from 'helpers/api/awards';

import { useQueryClient } from 'react-query';

export default function useActions() {
  const queryClient = useQueryClient();

  const onDownload = (invoiceID) => {
    getPDF(invoiceID)
      .then((data) => {
        const fileURL = URL.createObjectURL(data);

        window.open(fileURL);
      })
      .catch((getInvoicePDFError) => console.log({ getInvoicePDFError }));
  };
  const onSetPaid = (invoiceId, name, type, total) => {
    if (
      !confirm(
        `${name} - ${type} - $${total}\n\n`
          + 'You’re about to set this invoice as paid by applying a manual payment\n\n'
          + 'Are you sure you want to continue? This action cannot be reversed.'
      )
    ) {
      return;
    }
    setPaid(invoiceId)
      .then(
        () => alert('Invoice has been marked as paid.'),
        (error) => alert(`!!! Error marking invoice as paid:\n\n${error}`)
      )
      .then(() => {
        queryClient.invalidateQueries(['account.invoices']);
      });
  };
  const onCompleteInvoice = (invoiceId, name, type, total, callbackIsV1) => {
    if (
      !confirm(
        `${name} - ${type} - $${total}\n\n`
          + 'You’re about to complete this invoice giving out the licences, points or vouchers pending for this invoice\n\n'
          + 'Are you sure you want to continue? This action cannot be reversed.'
      )
    ) {
      return;
    }

    let functionToCall = callCallbackURL;
    const isV1 = !!callbackIsV1;

    if (type === 'awards' && isV1) {
      // Legacy SA invoices need to be completed in V1
      functionToCall = completeWiretransferPurchase;
    }
    functionToCall(invoiceId)
      .then(
        () => alert('Invoice has been completed.'),
        (error) => alert(`!!! Error completing invoice:\n\n${error}`)
      )
      .then(() => {
        queryClient.invalidateQueries(['invoices']);
      });
  };

  return React.useMemo(
    () => ({
      onDownload,
      onSetPaid,
      onCompleteInvoice,
    }),
    // Only depends on the queryClient
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryClient]
  );
}
