import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { DataGrid } from '@material-ui/data-grid';
// helpers
import { getInvoices } from 'helpers/api/payments';
// Our components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ApplauzButton from 'components/CustomButtons/Button';
import Textbox from 'components/Applauz/FormElements/Textbox';
import CustomInput from 'components/CustomInput/CustomInput';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

// imports specific to this module
import useColumns from './useColumns';
import { InvoiceStatus } from '@applauz/enums';
import { capitalize } from 'lodash';

const Homepage = () => {
  const navigate = useNavigate();
  const columns = useColumns();
  const [invoices, setInvoices] = React.useState([]);
  const [loadInvoicesTextValue, setLoadInvoicesTextValue] = React.useState('');
  const [getInvoicesFilter, setGetInvoicesFilter] = React.useState('');
  const [sortModel, setSortModel] = React.useState([]);
  const [filterString, setFilterString] = React.useState('');
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    linkOperator: 'or',
  });

  const { data: invoicesData, isFetching } = useQuery(
    ['account.invoices', getInvoicesFilter],
    () => {
      let xeroInvoiceNumberFilter = undefined;
      let organizationNameFilter = undefined;

      if (getInvoicesFilter !== '') {
        if (
          getInvoicesFilter.startsWith('INV-')
          || getInvoicesFilter.startsWith('QTE-')
        ) {
          xeroInvoiceNumberFilter = getInvoicesFilter;
        } else {
          organizationNameFilter = getInvoicesFilter;
        }
      }
      return getInvoices(organizationNameFilter, xeroInvoiceNumberFilter).then(
        (data) =>
          data.map((invoice) => ({
            ...invoice,
            id: invoice.invoiceId,
            tsInserted: moment(invoice.tsInserted)
              .local()
              .format('YYYY-MM-DD HH:mm'),
            status: capitalize(InvoiceStatus[invoice.statusId]),
            paymentType:
              invoice.payments.length > 1
                ? `${invoice.paymentType} + ${invoice.payments.length - 1}`
                : invoice.paymentType || 'N/A',
          }))
      );
    },
    {
      placeholderData: [],
    }
  );

  React.useEffect(() => {
    setFilterModel({
      // prettier-ignore
      items: [
        { id: 1, columnField: 'organizationName',  operatorValue: 'contains', value: filterString },
        { id: 2, columnField: 'xeroInvoiceNumber', operatorValue: 'contains', value: filterString },
      ],
      linkOperator: 'or',
    });
  }, [filterString]);

  React.useEffect(() => {
    setInvoices(invoicesData || []);
  }, [invoicesData]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={6}>
          <ApplauzButton
            style={{
              marginBottom: 35,
            }}
            color="info"
            onClick={() => navigate('/admin/invoices/new')}
          >
            Create new invoice
          </ApplauzButton>
        </GridItem>
        <GridItem xs={3}>
          <Textbox
            // value={''}
            placeholder="Load by Org Name or Invoice Number"
            onChange={(value) => setLoadInvoicesTextValue(value)}
          />
        </GridItem>
        <GridItem xs={3}>
          <ApplauzButton
            color="secondary"
            onClick={() => setGetInvoicesFilter(loadInvoicesTextValue)}
          >
            Load
          </ApplauzButton>
        </GridItem>

        <GridItem xs={12}>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                {' '}
                <h4>Search</h4>
                <CustomInput
                  labelText="Filter"
                  id="filterInvoices"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    value: filterString,
                    onChange: (event) => {
                      setFilterString(event.target.value);
                    },
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridItem>

        <GridItem xs={12}>
          This list is limited to the last 1000 invoices and quotes. To load
          more invoices use the load button above.
        </GridItem>
        <GridItem xs={12}>
          <DataGrid
            filterModel={filterModel}
            loading={isFetching}
            rowCount={invoices.length}
            rows={invoices}
            getRowId={(row) => row.invoiceId}
            columns={columns}
            hideFooterPagination={isFetching}
            pageSize={50}
            autoHeight
            sortingOrder={['asc', 'desc']}
            onSortModelChange={(model) => {
              if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
                setSortModel(model);
              }
            }}
            sortModel={sortModel}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              sorting: true,
              pageSize: 20,
              pageSizeOptions: [20, 50, 100],
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Homepage;
