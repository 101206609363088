function formatPrice(price, currency) {
  if (!currency) throw new Error('currency is required');
  return new Intl.NumberFormat('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency,
    currencyDisplay: currency ? 'code' : 'narrowSymbol',
  }).format(price);
}

export default formatPrice;
