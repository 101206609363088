"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostTypes = void 0;
const REGULAR = 1;
const RECOGNITION = 2;
const LAUNCH = 8;
const BIRTHDAY = 9;
const ANNIVERSARY = 10;
const COMMENT = 11;
const GOAL = 12;
const POLL = 13;
const NEW_EMPLOYEE = 14;
exports.PostTypes = {
    // Moved to enums/PostType
    REGULAR,
    RECOGNITION,
    LAUNCH,
    BIRTHDAY,
    ANNIVERSARY,
    COMMENT,
    GOAL,
    POLL,
    NEW_EMPLOYEE,
    // Moved to enums/PostMediaType
    MEDIA: {
        GIF: 'gif',
        DOCUMENT: 'document',
        IMAGE: 'image',
        VIDEO: 'video',
    },
    // Undecided, left here for now
    POST_LIKE: [REGULAR, POLL, COMMENT],
    RECOGNITION_LIKE: [RECOGNITION, GOAL, ANNIVERSARY, BIRTHDAY],
    INTERACTIONS: {
        AWARD: 1,
        REACTION: 2,
        COMMENT: 3,
    },
    // The rest should probably remain in constants
    FILTER: {
        LIMIT: 20,
    },
    ID_TO_LITERAL: {
        1: 'post',
        2: 'recognition',
        8: 'launch',
        9: 'birthday',
        10: 'anniversary',
        11: 'comment',
        12: 'goal',
    },
    EMAIL_NOTIFICATION_TYPES: {
        EVERYONE: 'everyone',
        NONE: 'none',
    },
    get POST_USERS_TYPES() {
        return [this.RECOGNITION, this.BIRTHDAY, this.ANNIVERSARY, this.GOAL];
    },
    get TYPE_TO_PERMISSION() {
        return {
            [this.REGULAR]: 'view:post',
            [this.RECOGNITION]: 'view:recognition',
            [this.BIRTHDAY]: 'view:post',
            [this.ANNIVERSARY]: 'view:post',
            [this.LAUNCH]: 'view:post',
            [this.GOAL]: 'view:goal_post',
            [this.NEW_EMPLOYEE]: 'view:post',
        };
    },
};
