"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerkFrequencyOptions = void 0;
exports.PerkFrequencyOptions = {
    WEEKLY: { key: 'weekly', text: 'frequency_weekly', type: 'weeks', count: 1 },
    BIWEEKLY: {
        key: 'bi-weekly',
        text: 'frequency_bi_weekly',
        type: 'weeks',
        count: 2,
    },
    MONTHLY: {
        key: 'monthly',
        text: 'frequency_monthly',
        type: 'months',
        count: 1,
    },
    YEARLY: { key: 'yearly', text: 'frequency_yearly', type: 'years', count: 1 },
};
