// We need to disable this because some endpoints
// expect the properties in snake_case.
/* eslint-disable camelcase */

import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UpdatePlanForm from './UpdatePlanForm';
import CancelPlanForm from './CancelPlanForm';
import {
  cancelUpcomingPlan,
  updateUpcomingPlan,
  updatePrice,
} from 'helpers/api/licences';
import useDiscountRate from './useDiscountRate';
import useGeneratedQuote from './useGeneratedQuote';
import useHasChanges from './useHasChanges';
import useUpcomingDates from './useUpcomingDates';
import useWarningText from './useWarningText';
import GeneratedQuoteDialog from './GeneratedQuoteDialog';
import UpdatePlanConfirmationModal from './UpdatePlanConfirmationModal';

const Tab = {
  NEXT_TERM: 'NEXT_TERM',
  IMMEDIATELY: 'IMMEDIATELY',
  CANCEL: 'CANCEL',
};

export default function UpdatePlanModal({
  currentSubscription,
  currentPlan,
  upcomingPlan,
  isOpen,
  onClose,
  onEndOfTermCancel,
  onImmediateCancel,
}) {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);
  const [tab, setTab] = React.useState(Tab.NEXT_TERM);
  const applyNow = tab === Tab.IMMEDIATELY;
  const [selectedPackage, setSelectedPackage] = useState({
    typeId: null,
    licencesCount: 0,
    name: '',
    length: '',
    pricePerLicence: 0,
    originalPricePerLicence: 0,
    discounts: [],
  });

  const organizationId = currentPlan?.organization_id;

  useEffect(() => {
    setSelectedPackage({
      typeId: null,
      licencesCount: 0,
      name: '',
      length: '',
      pricePerLicence: 0,
      originalPricePerLicence: 0,
      discounts: [],
    });
  }, [applyNow]);

  const getUpcomingDates = useUpcomingDates(
    selectedPackage,
    currentPlan,
    applyNow
  );
  const discountTotalRate = useDiscountRate(selectedPackage);
  const onSelectedPlanChangeHandler = (selectedPackageDetails) => {
    const { type, newLicences: licencesCount } = selectedPackageDetails;
    const {
      typeId,
      language,
      name,
      length,
      ppl,
      originalPricePerLicence,
      discounts,
    } = !!type && type;

    if (selectedPackageDetails) {
      setSelectedPackage((prev) => {
        const newPackage = {
          ...prev,
          typeId,
          licencesCount,
          name: language?.en_ca || name,
          length,
          pricePerLicence: ppl,
          originalPricePerLicence,
          discounts,
        };
        // Only update the state if the new package details are different from the current ones,
        // and this prevents from re-rendering issue.
        if (JSON.stringify(prev) !== JSON.stringify(newPackage)) {
          return newPackage;
        }
        return prev;
      });
    }
  };

  // this set customized price in db
  const onUpdateCustomPriceHandler = () => {
    if (!selectedPackage?.typeId) return;
    if (
      parseInt(selectedPackage.originalPricePerLicence)
      === parseInt(selectedPackage.pricePerLicence)
    ) {
      return;
    }

    const { startOfNewTerm, endOfNewTerm } = getUpcomingDates();
    console.log({
      selectedPricePerLicence: selectedPackage.pricePerLicence,
    });
    return updatePrice({
      organizationID: parseInt(organizationId),
      ppu: parseFloat(selectedPackage.pricePerLicence).toFixed(2),
      // if ppu needs to count with discounts, the next line should be uncommented
      // ppu: +(selectedPackage.pricePerLicence * discountTotalRate).toFixed(2)
      type: selectedPackage.typeId,
      dt_start: startOfNewTerm,
      dt_end: endOfNewTerm,
    });
  };

  const onEndHandler = () => {
    setWarningModalIsOpen(false);
    onClose();
    queryClient.invalidateQueries('upcomingPlan');
    queryClient.invalidateQueries('account');
    setIsLoading(false);
  };

  /**
   * for "Apply at the end of the term" option
   **/
  const onUpdateUpcomingPlanHandler = async () => {
    {
      setIsLoading(true);
      try {
        if (upcomingPlan?.typeId) {
          // If there is already an upcoming plan, cancel it first (to replace it with a new one)
          await cancelUpcomingPlan({
            organizationId: parseInt(organizationId),
          });
        }

        await updateUpcomingPlan({
          organizationId: parseInt(organizationId),
          newSeats: parseInt(selectedPackage.licencesCount),
          newTypeId: parseInt(selectedPackage.typeId),
        });

        // set customized price in db
        await onUpdateCustomPriceHandler();

        // invalidate queries (upcomingPlan and account)
        onEndHandler();
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  };

  /**
   * for "Apply now" option
   **/
  const generatedQuote = useGeneratedQuote(
    'licenceSubscription',
    organizationId
  );
  const onGenerateQuoteHandler = async (values) => {
    setIsLoading(true);
    try {
      // quote
      await generatedQuote.generate(values);
      // adds custom price to db after quote is generated
      await onUpdateCustomPriceHandler();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const hasChanges = useHasChanges(selectedPackage, currentPlan, upcomingPlan);
  const warningText = useWarningText(
    selectedPackage,
    getUpcomingDates,
    discountTotalRate
  );

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>
          <Box
            display="flex"
            flex={1}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Typography variant="h6">Change Plan</Typography>
            {typeof onClose === 'function' ? (
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="applyNow"
                name="applyNow"
                value={tab}
                onChange={(_, value) => {
                  setTab(value);
                }}
              >
                <FormControlLabel
                  value={Tab.NEXT_TERM}
                  control={<Radio color="primary" />}
                  label="Apply at the end of the term"
                />
                <FormControlLabel
                  value={Tab.IMMEDIATELY}
                  control={<Radio color="primary" />}
                  label="Apply immediately"
                />
                <FormControlLabel
                  value={Tab.CANCEL}
                  control={<Radio color="secondary" />}
                  label="Cancel"
                />
              </RadioGroup>
            </FormControl>

            {tab !== Tab.CANCEL ? (
              <UpdatePlanForm
                currentSubscription={currentSubscription}
                currentPlan={currentPlan}
                disabledSubmitButton={!hasChanges}
                onQuoteGenerate={applyNow ? onGenerateQuoteHandler : null}
                onGeneratedQuoteURLClear={
                  applyNow ? generatedQuote.clearURL : null
                }
                onUpdateNextPlan={
                  !applyNow
                    ? () => {
                        setWarningModalIsOpen(true);
                      }
                    : null
                }
                onSelectedPlanChange={onSelectedPlanChangeHandler}
                isApplyNow={applyNow}
              />
            ) : (
              <CancelPlanForm
                currentPlan={currentPlan}
                onEndOfTermCancel={onEndOfTermCancel}
                onImmediateCancel={onImmediateCancel}
              />
            )}
          </DialogContentText>
        </DialogContent>

        {/* Apply  at the end of next term only
            It needs to place the 2nd modal inside the Dialog to make the confirm button work. 
        */}
        <UpdatePlanConfirmationModal
          warningModalIsOpen={warningModalIsOpen}
          setWarningModalIsOpen={setWarningModalIsOpen}
          onUpdateUpcomingPlanHandler={onUpdateUpcomingPlanHandler}
          warningText={warningText}
          isLoading={isLoading}
        />

        <GeneratedQuoteDialog
          isOpen={generatedQuote.dialogIsOpen}
          onClose={generatedQuote.closeDialog}
          url={generatedQuote.url}
          organizationId={organizationId}
        />
      </Dialog>
    </>
  );
}
