/**
 * NOTES:
 * paymentFrequency was requested to be commented out,
 * to be implemented in the future. Search for this string
 * to find all of the places that need to be fixed:
 * TEMPORARILY REMOVED, SEE NOTES AT TOP
 */

import React from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
} from '@material-ui/core';
import formatPrice from 'helpers/lib/formatPrice';
import getSubscriptionTotalDiscounts from 'helpers/lib/getSubscriptionTotalDiscounts';
import useSubscriptionCustomDiscounts from 'helpers/lib/useSubscriptionCustomDiscounts';
import useSubscriptionPackagePicker from 'helpers/lib/useSubscriptionPackagePicker';
import GenerateQuoteButton from '../GenerateQuoteButton';
import GridDivider from './GridDivider';
import PackagePicker from './PackagePicker';
import DiscountRow from './DiscountRow';
import moment from 'moment';

function LicenceSubscription({
  currency,
  onQuoteGenerate,
  onGeneratedQuoteURLClear,
  customPrices,
}) {
  const packagePicker = useSubscriptionPackagePicker();

  const customDiscounts = useSubscriptionCustomDiscounts();

  const customPrice = React.useMemo(() => {
    return customPrices?.find(
      (item) =>
        item.type_id === packagePicker.selectedContractTerm?.typeId
        && moment().isBetween(item.start, moment(item.end).endOf('day'))
    )?.ppu;
  }, [customPrices, packagePicker.selectedContractTerm?.typeId]);

  const [licencesCount, setLicencesCount] = React.useState(10);

  const initialPricePerLicence =
    customPrice || packagePicker.selectedContractTerm?.pricePerLicence || 0;

  const [pricePerLicence, setPricePerLicence] = React.useState(
    initialPricePerLicence
  );
  React.useEffect(() => {
    setPricePerLicence(initialPricePerLicence);
  }, [initialPricePerLicence]);

  const totalPrice = pricePerLicence * licencesCount;
  const totalDiscounts = getSubscriptionTotalDiscounts({
    pricePerLicence,
    licencesCount,
    autoDiscounts: [],
    customDiscounts,
  });
  const subTotal = totalPrice - totalDiscounts;

  const handleQuoteGenerate = () => {
    onQuoteGenerate({
      newLicences: licencesCount,
      type: {
        ...packagePicker.selectedContractTerm,
        id: packagePicker.selectedContractTerm.typeId,
        discounts: customDiscounts.list,
        // TEMPORARILY REMOVED, SEE NOTES AT TOP
        // paymentFrequency: selectedPaymentFrequencyKey,
        currency,
        ppl: pricePerLicence,
        pricePerLicence,
      },
    });
  };

  React.useEffect(() => {
    onGeneratedQuoteURLClear();
  }, [
    onGeneratedQuoteURLClear,
    packagePicker.selectedContractTerm,
    customDiscounts.list,
    packagePicker.selectedPaymentFrequencyKey,
    pricePerLicence,
  ]);

  // TEMPORARILY REMOVED, SEE NOTES AT TOP
  const hasPaymentFrequencySelect =
    false
    && packagePicker.selectedContractTerm
    && Object.keys(packagePicker.paymentFrequencyOptions).length;

  const isLoading = packagePicker.packageOptions.length < 1;

  const getCanGenerateQuote = () => {
    if (!packagePicker.selectedContractTerm) return false;
    if (!licencesCount) return false;
    // TEMPORARILY REMOVED, SEE NOTES AT TOP
    // if (!selectedPaymentFrequencyKey) return false;
    return subTotal >= 0 && customDiscounts.areValid;
  };
  const canGenerateQuote = getCanGenerateQuote();

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: 320,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress style={{ color: '#00acc1' }} />
      </div>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }} p={2}>
      <Grid container spacing={2} alignItems="center">
        {/* Package Picker */}
        <Grid item xs={6}>
          <PackagePicker
            packageOptions={packagePicker.packageOptions}
            selectedPackageKey={packagePicker.selectedPackageKey}
            setSelectedPackageKey={packagePicker.setSelectedPackageKey}
            contractTermOptions={packagePicker.contractTermOptions}
            selectedContractTermTypeId={
              packagePicker.selectedContractTermTypeId
            }
            setSelectedContractTermTypeId={
              packagePicker.setSelectedContractTermTypeId
            }
            paymentFrequencyOptions={packagePicker.paymentFrequencyOptions}
            selectedPaymentFrequencyKey={
              packagePicker.selectedPaymentFrequencyKey
            }
            setSelectedPaymentFrequencyKey={
              hasPaymentFrequencySelect
                ? packagePicker.setSelectedPaymentFrequencyKey
                : undefined
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            variant="outlined"
            label="Price per licence"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChangeCapture={(event) => {
              const newValue = isNaN(event.target.value)
                ? ''
                : event.target.value;

              setPricePerLicence(newValue);
            }}
            onBlur={() => {
              const newValue = parseFloat(pricePerLicence);
              setPricePerLicence((isNaN(newValue) ? 0 : newValue).toFixed(2));
            }}
            value={pricePerLicence}
            type="number"
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            variant="outlined"
            label="No. of licences"
            type="number"
            value={licencesCount}
            onChange={(event) => {
              setLicencesCount(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            variant="outlined"
            disabled
            label="Total"
            value={formatPrice(totalPrice, currency)}
          />
        </Grid>
        {/* Discounts */}
        {customDiscounts.list.map((discount, index) => {
          return (
            // In this case, index is a valid use of key because
            // we are generating an array and editing values
            // by index.
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <GridDivider />

              <DiscountRow
                discount={discount}
                quantity={licencesCount}
                pricePerLicence={pricePerLicence}
                onRemove={() => customDiscounts.remove(index)}
                onDescriptionChange={(event) => {
                  customDiscounts.editDescription(
                    index,
                    discount,
                    event.target.value
                  );
                }}
                onPercentageChange={(event) => {
                  customDiscounts.editPercentage(
                    index,
                    discount,
                    event.target.value
                  );
                }}
                currency={currency}
              />
            </React.Fragment>
          );
        })}

        <GridDivider />

        {/* Footer */}
        <Grid item xs={8} />
        <Grid item xs={2}>
          <Typography variant="body1" align="right">
            Subtotal:{' '}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" align="right">
            <strong style={subTotal < 0 ? { color: 'red' } : undefined}>
              {formatPrice(subTotal, currency)}
            </strong>
          </Typography>
        </Grid>

        <GridDivider />

        <Grid item xs={6}>
          <Button variant="contained" onClick={customDiscounts.add}>
            Add discount
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <GenerateQuoteButton
              disabled={!canGenerateQuote}
              onClick={handleQuoteGenerate}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LicenceSubscription;
