"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Favorabilities = void 0;
const locales_1 = require("../../locales");
exports.Favorabilities = [
    {
        key: 'exceptional',
        threshold: 9,
        color: 'green',
        icon: 'fa-heart',
        description: {
            en: locales_1.PulseSurveysLocales.Favorabilities.en['pulse-surveys/favorabilities/exceptional'],
            fr: locales_1.PulseSurveysLocales.Favorabilities.fr['pulse-surveys/favorabilities/exceptional'],
        },
    },
    {
        key: 'favorable',
        threshold: 7,
        color: 'green',
        icon: 'fa-heart',
        description: {
            en: locales_1.PulseSurveysLocales.Favorabilities.en['pulse-surveys/favorabilities/favorable'],
            fr: locales_1.PulseSurveysLocales.Favorabilities.fr['pulse-surveys/favorabilities/favorable'],
        },
    },
    {
        key: 'at-risk',
        threshold: 5,
        color: 'yellow',
        icon: 'fa-heart',
        description: {
            en: locales_1.PulseSurveysLocales.Favorabilities.en['pulse-surveys/favorabilities/at-risk'],
            fr: locales_1.PulseSurveysLocales.Favorabilities.fr['pulse-surveys/favorabilities/at-risk'],
        },
    },
    {
        key: 'unfavorable',
        threshold: 3,
        color: 'yellow',
        icon: 'fa-heart',
        description: {
            en: locales_1.PulseSurveysLocales.Favorabilities.en['pulse-surveys/favorabilities/unfavorable'],
            fr: locales_1.PulseSurveysLocales.Favorabilities.fr['pulse-surveys/favorabilities/unfavorable'],
        },
    },
    {
        key: 'problematic',
        threshold: 0,
        color: 'gray',
        icon: 'fa-heart',
        description: {
            en: locales_1.PulseSurveysLocales.Favorabilities.en['pulse-surveys/favorabilities/problematic'],
            fr: locales_1.PulseSurveysLocales.Favorabilities.fr['pulse-surveys/favorabilities/problematic'],
        },
    },
];
