"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyDBIdToCodeMap = void 0;
const enums_1 = require("../../enums");
exports.CurrencyDBIdToCodeMap = {
    [enums_1.CurrencyDBId.CAD]: enums_1.CurrencyCode.CAD,
    [enums_1.CurrencyDBId.USD]: enums_1.CurrencyCode.USD,
    [enums_1.CurrencyDBId.GBP]: enums_1.CurrencyCode.GBP,
    [enums_1.CurrencyDBId.AUD]: enums_1.CurrencyCode.AUD,
};
