"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferenceOptions = void 0;
/**
 * Custom preferences types and values
 * These values are used for mounting the component and validation in the back end
 *
 * When different types/components become available in options, the followings need to be updated
 *
 * Add locales here: (follow the pattern "key_name.value")
 * file://./../../../projects/hub/client/src/hooks/preferences/locales/fr.json
 * file://./../../../projects/hub/client/src/hooks/preferences/locales/en.json
 *
 * Check here if need to add different component:
 * file://./../../../projects/hub/client/src/hooks/preferences/formatPreferences.js
 *
 * Check here if need to add validation:
 * file://./../../../projects/hub/server/controllers/preferences/setPreference/index.js
 **/
exports.PreferenceOptions = {
    birthday_announce_visibility: {
        type: 'single',
        options: ['everyone', 'departments', 'tagged'],
    },
    work_anniversary_announce_visibility: {
        type: 'single',
        options: ['everyone', 'departments', 'tagged'],
    },
    default_post_visibility: {
        type: 'single',
        options: ['everyone', 'custom'],
    },
    badge_limit_per_recognition: {
        type: 'single',
        options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
};
