import { get, post } from '.';
import { InvoiceStatus } from '@applauz/enums';

export const getPDF = (invoiceId) =>
  get(`${process.env.REACT_APP_HUB_SERVER_URL}/organization/downloadInvoices`, {
    params: { invoiceId },
    responseType: 'blob',
  });

export const getAdminFees = () => get('as://payments/admin/adminFees');

// CREATE/UPDATE/DELETE admin fee
const fieldsToPost = [
  'isNew',
  'isDelete',
  'id',
  'percentage',
  'on_product',
  'on_purchase',
  'province_id',
  'supplier_id',
  'amount',
];
export const updateAdminFees = (params) => {
  const postParams = {};
  // this is to not unecessarily post front-end related data that was passed with params object
  fieldsToPost.forEach((field) => {
    if (field in params) postParams[field] = params[field];
  });
  return post('as://payments/admin/adminFees', { params: postParams });
};
export const getInvoices = (organizationName, xeroInvoiceNumber) =>
  get(`${process.env.REACT_APP_HUB_SERVER_URL}/invoices/getInvoices`, {
    params: {
      organizationName,
      xeroInvoiceNumber,
      statusIds: [
        InvoiceStatus.QUOTE,
        InvoiceStatus.INVOICED,
        InvoiceStatus.PAID,
        InvoiceStatus.COMPLETED,
      ].join(','),
    },
  });

export const callCallbackURL = (invoiceId) =>
  get(`${process.env.REACT_APP_HUB_SERVER_URL}/invoices/callCallbackURL`, {
    params: { invoiceId },
  });

export const setPaid = async (invoiceId) => {
  return await post(
    `${process.env.REACT_APP_HUB_SERVER_URL}/invoices/setPaid`,
    { params: { invoiceId } }
  );
};
