import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button as MUIButton,
} from '@material-ui/core';
import PriceInput from 'components/Applauz/FormElements/PriceInput';
import Button from 'components/CustomButtons/Button';
import { Alert, AlertTitle } from '@material-ui/lab';
import formatPrice from 'helpers/lib/formatPrice';

export default function CustomPriceModal({
  isOpen,
  onClose,
  isLoading,
  subscriptionPrice,
  setSubscriptionPrice,
  subscriptionTypes,
  dateIsValid,
  onNewPriceSubmit,
  currentPlan,
  upcomingPlan,
  currency,
}) {
  const hasUpcomingPlan = !_.isEmpty(upcomingPlan);
  const hasUpcomingPlanStartDatePriceWarning =
    !!upcomingPlan?.startDate
    && !!subscriptionPrice?.dt_start
    && moment(subscriptionPrice.dt_start).isAfter(upcomingPlan.startDate)
    && upcomingPlan?.pricePerLicence?.toFixed(2) !== subscriptionPrice.ppu;

  const upcomingPlanEndDate = React.useMemo(() => {
    if (hasUpcomingPlan && upcomingPlan.length) {
      const [length, unit] = upcomingPlan.length.split(' ');
      const date = moment(upcomingPlan.startDate).add(length, unit);

      if (date.isValid()) {
        return date.format('YYYY-MM-DD');
      }
    }

    return '';
  }, [hasUpcomingPlan, upcomingPlan]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Custom Price</DialogTitle>
      <DialogContent>
        <form noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PriceInput
                id="standard-basic"
                label="Price Per Unit"
                name="ppu"
                value={subscriptionPrice.ppu}
                onChange={(e) =>
                  setSubscriptionPrice({
                    ...subscriptionPrice,
                    ppu: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="package-picker">Package</InputLabel>
                <Select
                  labelId="package-picker"
                  value={`${subscriptionPrice.type || ''}`}
                  placeholder="Select Subscription Type"
                  onChange={(e) => {
                    setSubscriptionPrice({
                      ...subscriptionPrice,
                      type: parseInt(e.target.value),
                    });
                  }}
                >
                  {subscriptionTypes.map((subscriptionType) => {
                    return (
                      <MenuItem
                        key={subscriptionType.value}
                        value={subscriptionType.value}
                      >
                        {subscriptionType.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="date-start"
                label="Start Date"
                type="date"
                value={subscriptionPrice.dt_start}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: { min: moment().format('YYYY-MM-DD') },
                }}
                onChange={(e) => {
                  setSubscriptionPrice({
                    ...subscriptionPrice,
                    dt_start: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled={!subscriptionPrice.dt_start}
                id="date-end"
                label="End Date"
                type="date"
                value={subscriptionPrice.dt_end}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: {
                    min: moment(subscriptionPrice.dt_start)
                      .add(1, 'day')
                      .format('YYYY-MM-DD'),
                  },
                }}
                onChange={(e) =>
                  setSubscriptionPrice({
                    ...subscriptionPrice,
                    dt_end: e.target.value,
                  })
                }
                error={!!subscriptionPrice.dt_end && !dateIsValid}
                helperText={
                  !subscriptionPrice.dt_end || dateIsValid
                    ? undefined
                    : 'The end date must be later than the start date.'
                }
              />
            </Grid>
            <Grid item xs={12}>
              Date range shortcuts:
              <MUIButton
                color="secondary"
                onClick={() => {
                  setSubscriptionPrice({
                    ...subscriptionPrice,
                    dt_start: moment().format('YYYY-MM-DD'),
                    dt_end: moment(currentPlan.dt_next_bill)
                      .subtract(1, 'day')
                      .format('YYYY-MM-DD'),
                  });
                }}
              >
                Current Plan
              </MUIButton>
              <MUIButton
                disabled={!hasUpcomingPlan}
                color="secondary"
                onClick={() => {
                  setSubscriptionPrice({
                    ...subscriptionPrice,
                    dt_start: moment(upcomingPlan.startDate).format(
                      'YYYY-MM-DD'
                    ),
                    dt_end: upcomingPlanEndDate,
                  });
                }}
              >
                Change Plan
              </MUIButton>
            </Grid>
            {hasUpcomingPlanStartDatePriceWarning && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  <AlertTitle>
                    The user will not see this custom price!
                  </AlertTitle>
                  Your upcoming plan change starts on{' '}
                  {moment(upcomingPlan.startDate).format('LL')}, but you are
                  programming this custom price to start on{' '}
                  {moment(subscriptionPrice.dt_start).format('LL')}. When the
                  user looks at their subscription on the app, they will see
                  {formatPrice(upcomingPlan.pricePerLicence, currency)} instead
                  of {formatPrice(subscriptionPrice.ppu, currency)}.
                </Alert>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={onNewPriceSubmit}
          color={isLoading ? 'github' : 'info'}
          disabled={isLoading || !dateIsValid}
        >
          Set Price
          {isLoading && <CircularProgress size={24} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
