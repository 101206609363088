"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyCodeToDBIdMap = void 0;
const enums_1 = require("../../enums");
exports.CurrencyCodeToDBIdMap = {
    [enums_1.CurrencyCode.CAD]: enums_1.CurrencyDBId.CAD,
    [enums_1.CurrencyCode.USD]: enums_1.CurrencyDBId.USD,
    [enums_1.CurrencyCode.GBP]: enums_1.CurrencyDBId.GBP,
    [enums_1.CurrencyCode.AUD]: enums_1.CurrencyDBId.AUD,
};
