"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceAwardsGRSCatalogs = void 0;
const MERCHANDISE = {
    en: 'Merchandise',
    fr: 'Marchandise',
    zh: 'Merchandise',
};
const GIFT_CARDS = {
    en: 'Gift Cards',
    fr: 'Cartes cadeaux',
    zh: 'Gift Cards',
};
const EXPERIENTIAL = {
    en: 'Experiences',
    fr: 'Expériences',
    zh: 'Experiences',
};
// This map was generated from a spreadsheet provided by GRS
// It maps the country code to the catalogs codes available for that country
exports.ServiceAwardsGRSCatalogs = {
    // Canada
    CA: [
        // This is the default catalog for Canada
        // { code: 'CA_MRCH_GRS3', name: MERCHANDISE },
        // This is the LS catalog for Canada
        { code: 'LS_MERCH_APPLAUZ', name: MERCHANDISE },
        { code: 'CAD_GC_LIVE', name: GIFT_CARDS },
        { code: 'USCAD_EXP_LIVE', name: EXPERIENTIAL },
        {
            code: 'CHN_GIFTCARDS',
            name: {
                en: 'China Gift Cards',
                fr: 'China Cartes cadeaux',
                zh: 'China Gift Cards',
            },
        },
        {
            code: 'CHN_MRCH_GRS3',
            name: {
                en: 'China Merchandise',
                fr: 'China Marchandise',
                zh: 'China Merchandise',
            },
        },
        {
            code: 'SING_MERCH_GRS2',
            name: {
                en: 'Singapore Merchandise',
                fr: 'Singapour Marchandise',
                zh: 'Singapore Merchandise',
            },
        },
        {
            code: 'SING_GIFTCARDS_live',
            name: {
                en: 'Singapore Gift Cards',
                fr: 'Singapour Cartes cadeaux',
                zh: 'Singapore Gift Cards',
            },
        },
    ],
    // USA
    US: [
        { code: 'LS_MERCH_APPLAUZ', name: MERCHANDISE },
        { code: 'USA_MERCH_LIVE', name: MERCHANDISE },
        { code: 'US_GIFTCARDS', name: GIFT_CARDS },
        { code: 'USCAD_EXP_LIVE', name: EXPERIENTIAL },
        {
            code: 'APPLESTORE_MERCH-US',
            name: {
                en: 'Apple Store',
                fr: 'Apple Store',
                zh: 'Apple Store',
            },
        },
        {
            code: 'BEATS_US',
            name: {
                en: 'Beats by Dr. Dre',
                fr: 'Beats by Dr. Dre',
                zh: 'Beats by Dr. Dre',
            },
        },
        {
            code: 'CAB_US_MC-LIVE',
            name: {
                en: 'Bass Pro Shops - Cabelas',
                fr: 'Bass Pro Shops - Cabelas',
                zh: 'Bass Pro Shops - Cabelas',
            },
        },
    ],
    // Rest of the world
    AL: [{ code: 'ALB_MERCH-LIVE', name: MERCHANDISE }],
    AD: [{ code: 'AND_MERCH-LIVE', name: MERCHANDISE }],
    AG: [{ code: 'ATG_MERCH_LIVE', name: MERCHANDISE }],
    AR: [
        { code: 'ARG_MRCH_GRS', name: MERCHANDISE },
        { code: 'ARG_GIFTCARDS', name: GIFT_CARDS },
    ],
    AU: [{ code: 'AUS_MERCH_GRS', name: MERCHANDISE }],
    AT: [{ code: 'AST_MRCH_GRS1', name: MERCHANDISE }],
    AZ: [{ code: 'AZ_MERCH_LIVE', name: MERCHANDISE }],
    BH: [{ code: 'BHR_MERCH_LIVE', name: MERCHANDISE }],
    BD: [{ code: 'BNG_MRCH_GRS', name: MERCHANDISE }],
    BB: [{ code: 'BRB_MERCH_LIVE', name: MERCHANDISE }],
    BY: [{ code: 'BEL_MERCH-LIVE', name: MERCHANDISE }],
    BE: [{ code: 'BGM_MRCH_GRS1', name: MERCHANDISE }],
    BO: [{ code: 'BO_MERCH-LIVE', name: MERCHANDISE }],
    BA: [{ code: 'BOS_MERCH-LIVE', name: MERCHANDISE }],
    BR: [
        { code: 'BZL_MRCH_GRS', name: MERCHANDISE },
        { code: 'BR_GIFTCARDS', name: GIFT_CARDS },
    ],
    BG: [{ code: 'BGR_MERCH_LIVE', name: MERCHANDISE }],
    IC: [{ code: 'CANIS_MERCH-LIVE', name: MERCHANDISE }],
    CL: [
        { code: 'CHL_MRCH_GRS1', name: MERCHANDISE },
        { code: 'CHL_GIFTCARDS', name: GIFT_CARDS },
    ],
    CN: [
        { code: 'CHN_MRCH_GRS3', name: MERCHANDISE },
        { code: 'CHN_GIFTCARDS', name: GIFT_CARDS },
        { code: 'MCU_MERCH_LIVE', name: MERCHANDISE },
    ],
    CO: [
        { code: 'COL_MRCH_GRS1', name: MERCHANDISE },
        { code: 'COL_GIFTCARDS', name: GIFT_CARDS },
    ],
    CR: [{ code: 'CRC_MERCH_LIVE', name: MERCHANDISE }],
    CZ: [{ code: 'CZH_MRCH_GRS1', name: MERCHANDISE }],
    DK: [{ code: 'DNK_MERCH_LIVE', name: MERCHANDISE }],
    DM: [{ code: 'DO_MERCH_LIVE', name: MERCHANDISE }],
    DO: [{ code: 'DOM_MERCH_LIVE', name: MERCHANDISE }],
    EC: [{ code: 'ECU_MERCH_LIVE', name: MERCHANDISE }],
    EG: [{ code: 'EGY_MERCH_LIVE', name: MERCHANDISE }],
    SV: [
        { code: 'SV_MERCH_LIVE', name: MERCHANDISE },
        { code: 'SLV_GIFTCARDS', name: GIFT_CARDS },
    ],
    EE: [{ code: 'EST_MRCH_GRS1', name: MERCHANDISE }],
    FI: [{ code: 'FIN_MERCH_LIVE', name: MERCHANDISE }],
    FR: [
        { code: 'FRN_MRCH_GRS', name: MERCHANDISE },
        { code: 'FR_GIFTCARDS', name: GIFT_CARDS },
    ],
    GE: [{ code: 'GE_MERCH_LIVE', name: MERCHANDISE }],
    DE: [
        { code: 'DE_MERCH_LIVE', name: MERCHANDISE },
        { code: 'DE_GIFTCARDS', name: GIFT_CARDS },
    ],
    GI: [{ code: 'GBR_LIVE', name: MERCHANDISE }],
    GR: [{ code: 'GRC_MERCH_LIVE2', name: MERCHANDISE }],
    GT: [{ code: 'GTM_MERCH_LIVE', name: MERCHANDISE }],
    HN: [
        { code: 'HND_MERCH_LIVE', name: MERCHANDISE },
        { code: 'HND_GIFTCARDS', name: GIFT_CARDS },
    ],
    HK: [
        { code: 'HK_MRCH_GRS1', name: MERCHANDISE },
        { code: 'HK_GIFTCARDS', name: GIFT_CARDS },
    ],
    HU: [{ code: 'HU_MERCH_LIVE2', name: MERCHANDISE }],
    IS: [{ code: 'ICE_MERCH-LIVE', name: MERCHANDISE }],
    IN: [
        { code: 'IN_MERCH_GRS2', name: MERCHANDISE },
        { code: 'INDIA_GC', name: GIFT_CARDS },
    ],
    ID: [
        { code: 'INDO_MERCH_GRS', name: MERCHANDISE },
        { code: 'IND_GIFTCARDS_LIVE', name: GIFT_CARDS },
    ],
    IE: [
        { code: 'IRLD_MRCH_GRS', name: MERCHANDISE },
        { code: 'IRE_GIFTCARDS', name: GIFT_CARDS },
    ],
    IL: [{ code: 'IRL_MRCH_GRS', name: MERCHANDISE }],
    IT: [
        { code: 'ITL_MRCH_GRS2', name: MERCHANDISE },
        { code: 'IT_GIFTCARDS', name: GIFT_CARDS },
    ],
    JM: [{ code: 'JAM_MERCH_LIVE', name: MERCHANDISE }],
    JP: [{ code: 'JPN_MRCH_GRS2', name: MERCHANDISE }],
    JE: [{ code: 'JRSY_LIVE', name: MERCHANDISE }],
    JO: [{ code: 'JO_MERCH_GRS', name: MERCHANDISE }],
    KZ: [{ code: 'KAZ_MERCH-LIVE', name: MERCHANDISE }],
    KE: [{ code: 'KEN_GIFTCARDS', name: GIFT_CARDS }],
    XK: [{ code: 'KOS_MERCH-LIVE', name: MERCHANDISE }],
    KW: [{ code: 'KWT_MERCH_LIVE', name: MERCHANDISE }],
    LV: [{ code: 'LAT_MRCH_LIVE', name: MERCHANDISE }],
    LT: [
        { code: 'LIT_MRCH_GRS1', name: MERCHANDISE },
        { code: 'LITH_GIFTCARDS', name: GIFT_CARDS },
    ],
    LU: [
        { code: 'LUX_GIFTCARDS', name: GIFT_CARDS },
        { code: 'LUX_MERCH_LIVE', name: MERCHANDISE },
    ],
    MK: [{ code: 'MKD_MERCH_LIVE', name: MERCHANDISE }],
    MY: [
        { code: 'MLY_MRCH_GRS', name: MERCHANDISE },
        { code: 'MYS_GC_LIVE', name: GIFT_CARDS },
    ],
    MT: [{ code: 'MLT_MERCH_LIVE', name: MERCHANDISE }],
    MX: [
        { code: 'MX_MRCH_GRS2', name: MERCHANDISE },
        { code: 'MEXICO_GC', name: GIFT_CARDS },
    ],
    MC: [{ code: 'MNC_LIVE', name: MERCHANDISE }],
    ME: [{ code: 'MONNEG_MERCH-LIVE', name: MERCHANDISE }],
    NL: [
        { code: 'NTH_MRCH_GRS1', name: MERCHANDISE },
        { code: 'NL_GIFTCARDS', name: GIFT_CARDS },
    ],
    NZ: [{ code: 'NWZ_MERCH_GRS', name: MERCHANDISE }],
    NI: [
        { code: 'NIC_MERCH_LIVE', name: MERCHANDISE },
        { code: 'NIC_GIFTCARDS', name: GIFT_CARDS },
    ],
    NG: [{ code: 'NGA_MERCH_LIVE', name: MERCHANDISE }],
    NO: [{ code: 'NOR_MERCH_LIVE', name: MERCHANDISE }],
    OM: [{ code: 'OMN_MERCH_LIVE', name: MERCHANDISE }],
    PK: [{ code: 'PK_MERCH_LIVE', name: MERCHANDISE }],
    PS: [{ code: 'PSE_MERCH_LIVE', name: MERCHANDISE }],
    PA: [
        { code: 'PAN_GIFTCARDS', name: GIFT_CARDS },
        { code: 'PA_MERCH_LIVE', name: MERCHANDISE },
    ],
    PY: [{ code: 'PRY_MERCH_LIVE', name: MERCHANDISE }],
    PE: [
        { code: 'PRU_MRCH_GRS1', name: MERCHANDISE },
        { code: 'PER_GIFTCARDS', name: GIFT_CARDS },
    ],
    PH: [
        { code: 'PHL_MRCH_GRS1', name: MERCHANDISE },
        { code: 'PH_GIFTCARDS', name: GIFT_CARDS },
    ],
    PL: [
        { code: 'POL_MRCH_GRS1', name: MERCHANDISE },
        { code: 'PL_GIFTCARDS', name: GIFT_CARDS },
    ],
    PT: [{ code: 'PGL_MRCH_GRS1', name: MERCHANDISE }],
    PR: [
        { code: 'PR_ELEC_GRS2', name: MERCHANDISE },
        { code: 'PRI_GIFTCARDS', name: GIFT_CARDS },
        { code: 'PR_ELEC_GRS2', name: MERCHANDISE },
    ],
    QA: [{ code: 'QAT_MERCH_LIVE', name: MERCHANDISE }],
    RO: [{ code: 'ROU_MERCH_LIVE', name: MERCHANDISE }],
    SA: [{ code: 'SAU_MERCH_LIVE', name: MERCHANDISE }],
    RS: [{ code: 'SERB_MERCH-LIVE', name: MERCHANDISE }],
    SG: [
        { code: 'SING_MERCH_GRS2', name: MERCHANDISE },
        { code: 'SING_GIFTCARDS_live', name: GIFT_CARDS },
    ],
    SK: [{ code: 'SK_MERCH_LIVE', name: MERCHANDISE }],
    SI: [{ code: 'SI_MERCH_LIVE', name: MERCHANDISE }],
    ZA: [
        { code: 'ZAF_MERCH_LIVE', name: MERCHANDISE },
        { code: 'ZAF_GIFTCARDS', name: GIFT_CARDS },
    ],
    KR: [
        { code: 'KOR_MERCH_LIVE', name: MERCHANDISE },
        { code: 'KOR_GIFTCARDS', name: GIFT_CARDS },
    ],
    ES: [
        { code: 'SPN_MRCH_GRS1', name: MERCHANDISE },
        { code: 'ES_GIFTCARDS', name: GIFT_CARDS },
    ],
    LK: [{ code: 'LKA_MRCH_LIVE', name: MERCHANDISE }],
    LC: [{ code: 'LC_MERCH_LIVE', name: MERCHANDISE }],
    SE: [{ code: 'SWE_MERCH_LIVE', name: MERCHANDISE }],
    CH: [{ code: 'CH_MERCH_GRS', name: MERCHANDISE }],
    TW: [
        { code: 'TWN_MRCH_GRS1', name: MERCHANDISE },
        { code: 'TW_GIFTCARDS', name: GIFT_CARDS },
    ],
    TH: [{ code: 'THL_MRCH_GRS', name: MERCHANDISE }],
    TT: [{ code: 'TTO_MERCH_LIVE', name: MERCHANDISE }],
    TR: [{ code: 'TUR_MERCH_LIVE', name: MERCHANDISE }],
    GB: [
        { code: 'UK_MRCH_LIVE', name: MERCHANDISE },
        { code: 'UK_GIFTCARDS', name: GIFT_CARDS },
    ],
    UA: [{ code: 'UA_GIFTCARDS', name: GIFT_CARDS }],
    UY: [{ code: 'URY_MERCH_LIVE', name: MERCHANDISE }],
    VN: [{ code: 'VN_MERCH_GRS', name: MERCHANDISE }],
};
