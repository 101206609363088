/**
 * NOTES:
 * paymentFrequency was requested to be commented out,
 * to be implemented in the future. Search for this string
 * to find all of the places that need to be fixed:
 * TEMPORARILY REMOVED, SEE NOTES AT TOP
 */

import React from 'react';
import moment from 'moment';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import formatPrice from 'helpers/lib/formatPrice';
import getSubscriptionTotalDiscounts from 'helpers/lib/getSubscriptionTotalDiscounts';
import useSubscriptionCustomDiscounts from 'helpers/lib/useSubscriptionCustomDiscounts';
import useSubscriptionPackagePicker from 'helpers/lib/useSubscriptionPackagePicker';
import { getCurrentSubscription } from 'helpers/api/licences';

import GridDivider from './GridDivider';
import DiscountRow from './DiscountRow';
import SelectedPackageRow from './SelectedPackageRow';
import ProratedCreditRow from './ProratedCreditRow';
import Footer from './Footer';
import getProratedCredit from './getProratedCredit';
import getNewProratedPrice from './getNewProratedPrice';
import useUpdateType from './useUpdateType';

function LicenceSubscription({
  isApplyNow,
  // for actions to update immediately in the parent component (List page)
  currentPlan,
  currentSubscription,
  onQuoteGenerate,
  onGeneratedQuoteURLClear,
  // for actions to update at the end of the current subscription period
  onUpdateNextPlan,
  onSelectedPlanChange,
  disabledSubmitButton,
}) {
  const [newPackage, setNewPackage] = React.useState({
    newPricePerLicence: 0,
    newTotalPerLicence: 0, // prorated price per licence (from the new package data)
  });
  const [licencesCount, setLicencesCount] = React.useState(10);
  const [proratedTotal, setProratedTotal] = React.useState(0);

  React.useEffect(() => {
    if (!currentPlan?.organization_id) return;
    // prorated price is availabe only from current subscription, which is not available in currentPlan.
    // so we need to get it from the server (subscription).
    getCurrentSubscription({
      organizationId: parseInt(currentPlan.organization_id),
    })
      .then((subscription) => {
        // use "subscription.proratedPrice" from the subscription, which is a total amount of single licence prorated price for the remaining days.
        // "subscription.proratedCredit" is  a total amount of prorated price for used days.
        // "subscription.proratedPrice" + "subscription.propratedCredit" = "subscription.pricePerLicence"(= licence fee for the plan).
        setProratedTotal(subscription?.proratedPrice);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [currentPlan.organization_id]);

  const packagePicker = useSubscriptionPackagePicker();
  React.useEffect(() => {
    packagePicker.reset();
    // Since it needs to be reset by selected radio button changes,
    // this is necessary to have it in the dependency array, even though it's not used.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApplyNow]);

  React.useEffect(() => {
    setLicencesCount(currentPlan?.licences || 10);
  }, [currentPlan?.licences]);

  React.useEffect(() => {
    if (!packagePicker?.selectedContractTerm) return;
    // proratedPrice is the prorated price per licence x remaining days.
    const { pricePerLicence, proratedPrice } =
      packagePicker.selectedContractTerm;

    if (
      (!isApplyNow && pricePerLicence)
      || (pricePerLicence && proratedPrice)
    ) {
      setNewPackage({
        newPricePerLicence: pricePerLicence,
        newTotalPerLicence: proratedPrice,
      });
    }
  }, [packagePicker.selectedContractTerm, isApplyNow]);

  const isImmediateUpdate = typeof onQuoteGenerate === 'function';
  const proratedCredit =
    isImmediateUpdate
    && getProratedCredit(
      currentPlan.dt_next_bill || currentPlan.billDate,
      currentPlan.length,
      currentPlan.licences,
      proratedTotal || 0
    );

  const newProratedPricePerLicence = getNewProratedPrice(
    packagePicker.selectedContractTerm,
    newPackage.newPricePerLicence
  );

  const newEndDate = React.useMemo(() => {
    if (!packagePicker?.selectedContractTerm?.length) return null;

    const newLength = packagePicker.selectedContractTerm.length.split(` `);
    const today = moment().startOf('day');
    if (newLength.length < 2) return null;

    const newDate = moment(today)
      .add(newLength[0], `${newLength[1]}s`)
      .startOf('month');

    return newDate.format('YYYY-MM-DD');
  }, [packagePicker.selectedContractTerm]);

  const customDiscounts = useSubscriptionCustomDiscounts();

  const totalPrice = isApplyNow
    ? newProratedPricePerLicence * licencesCount
    : newPackage.newPricePerLicence * licencesCount;

  const totalDiscounts = getSubscriptionTotalDiscounts({
    // pricePerLicence: newPackage.newPricePerLicence,
    pricePerLicence: isApplyNow
      ? newProratedPricePerLicence
      : newPackage.newPricePerLicence,
    licencesCount,
    autoDiscounts: [],
    customDiscounts,
    proratedCredit: isApplyNow ? proratedCredit.creditTotal : 0,
  });
  const subTotal = +(totalPrice - totalDiscounts).toFixed(2);

  // isApplyNow only
  const handleQuoteGenerate = () => {
    onQuoteGenerate({
      newLicences: licencesCount,
      subtotal: subTotal,
      type: {
        ...packagePicker.selectedContractTerm,
        id: packagePicker.selectedContractTerm.typeId,
        discounts: customDiscounts.list,
        currency: currentSubscription.priceCurrency,
        ppl: newProratedPricePerLicence,
        pricePerLicence: newProratedPricePerLicence,
        // TEMPORARILY REMOVED, SEE NOTES AT TOP
        // paymentFrequency: selectedPaymentFrequencyKey,
        startDate: moment().add(1, 'days').format('YYYY-MM-DD'),
        endDate: newEndDate,
        proratedCredit,
      },
    });
  };

  React.useEffect(() => {
    typeof onGeneratedQuoteURLClear === 'function'
      && onGeneratedQuoteURLClear();
  }, [
    onGeneratedQuoteURLClear,
    packagePicker.selectedContractTerm,
    customDiscounts.list,
    packagePicker.selectedPaymentFrequencyKey,
  ]);

  // TEMPORARILY REMOVED, SEE NOTES AT TOP
  const hasPaymentFrequencySelect =
    false
    && packagePicker.selectedContractTerm
    && Object.keys(packagePicker.paymentFrequencyOptions).length;

  const isLoading = packagePicker.packageOptions.length < 1;

  const updateType = useUpdateType(
    packagePicker,
    licencesCount,
    newPackage,
    currentPlan,
    isApplyNow
  );

  const canSubmit = React.useMemo(() => {
    return (
      updateType === 'upgrade' && subTotal >= 0 && customDiscounts.areValid
    );
  }, [updateType, subTotal, customDiscounts.areValid]);

  // use this data in the parent component (List page)
  React.useEffect(() => {
    if (typeof onSelectedPlanChange === 'function') {
      onSelectedPlanChange({
        newLicences: licencesCount,
        type: packagePicker.selectedContractTerm && {
          ...packagePicker.selectedContractTerm,
          id: packagePicker.selectedContractTerm.typeId,
          pricePerLicence: newPackage.newPricePerLicence,
          originalPricePerLicence:
            packagePicker.selectedContractTerm.pricePerLicence,
          ppl: newPackage.newPricePerLicence,
          currency: currentSubscription.priceCurrency,
          discounts: customDiscounts.list,
        },
      });
    }
  }, [
    onSelectedPlanChange,
    packagePicker.selectedContractTerm,
    customDiscounts.list,
    packagePicker.selectedPaymentFrequencyKey,
    licencesCount,
    newPackage.newPricePerLicence,
    currentSubscription.priceCurrency,
  ]);

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: 320,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress style={{ color: '#00acc1' }} />
      </div>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }} p={2}>
      <Grid container spacing={2} alignItems="center">
        {/* Package Picker row */}
        <SelectedPackageRow
          packagePicker={packagePicker}
          hasPaymentFrequencySelect={hasPaymentFrequencySelect}
          pricePerLicence={newPackage.newPricePerLicence}
          licencesCount={licencesCount}
          newProratedPricePerLicence={newProratedPricePerLicence} // to show under the price input
          newEndDate={newEndDate}
          totalPrice={formatPrice(
            totalPrice,
            currentSubscription.priceCurrency
          )}
          isApplyNow={isApplyNow}
          onPricePerLicenceChange={(newValue) => {
            setNewPackage((prev) => {
              return {
                ...prev,
                newPricePerLicence: newValue,
              };
            });
          }}
          onLicenceCountChange={setLicencesCount}
        />

        {/* Prorated Credit */}
        {isImmediateUpdate && updateType !== 'same' && (
          <>
            <GridDivider />
            <ProratedCreditRow
              proratedCredit={proratedCredit}
              currentOriginalPricePerLicence={currentPlan?.ppu}
              currency={currentSubscription.priceCurrency}
            />
          </>
        )}

        {/* Discounts */}
        {customDiscounts.list.map((discount, index) => {
          return (
            // In this case, index is a valid use of key because
            // we are generating an array and editing values by index.
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <GridDivider />

              <DiscountRow
                discount={discount}
                quantity={licencesCount}
                pricePerLicence={
                  isApplyNow
                    ? newProratedPricePerLicence
                    : newPackage.newPricePerLicence
                }
                onRemove={() => customDiscounts.remove(index)}
                onDescriptionChange={(event) => {
                  customDiscounts.editDescription(
                    index,
                    discount,
                    event.target.value
                  );
                }}
                onPercentageChange={(event) => {
                  customDiscounts.editPercentage(
                    index,
                    discount,
                    event.target.value
                  );
                }}
                currency={currentSubscription.priceCurrency}
              />
            </React.Fragment>
          );
        })}

        <GridDivider />

        {/* Footer */}
        <Footer
          canSubmit={canSubmit}
          customDiscounts={customDiscounts}
          disabledSubmitButton={disabledSubmitButton}
          formatPrice={formatPrice}
          subTotal={subTotal}
          updateType={updateType}
          isApplyNow={isImmediateUpdate}
          onUpdateNextPlan={onUpdateNextPlan}
          onQuoteGenerate={handleQuoteGenerate}
          currencyCode={currentSubscription.priceCurrency}
        />
      </Grid>
    </Box>
  );
}

export default LicenceSubscription;
