"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Payment = void 0;
exports.Payment = {
    // Moved to enums/PaymentStatus
    PENDING: 1,
    APPROVED: 2,
    DECLINED: 3,
    DUPLICATE: 4,
    DEMO: 5,
    // Moved to enums/PaymentXeroType
    XERO_TYPES: {
        ANNUAL_LICENCES: 'annual_licences',
        MONTHLY_LICENCES: 'monthly_licences',
        LICENCE_CREDITS: 'licence_credits',
        MONERIS: 'moneris',
        POINTS_PURCHASE: 'points_purchase',
        ADMIN_FEES: 'admin_fees',
        WIRE_TRANSFER: 'wireTransfer',
        EXEMPT: 'EXEMPT',
        AWARDS: 'awards',
        VOUCHER_LICENCES: 'voucher_licences',
        AWARDS_CREDIT: 'awards_credit',
    },
    // Moved to enums/PurchaseReference
    PURCHASE_SUBSCRIPTION_REFERENCE: 'Company Subscription Purchase',
    PURCHASE_SA_REFERENCE: 'Company Vouchers Purchase',
    // Moved to enums/InvoiceStatus (minus the getCompleted getter)
    INVOICE_STATUSES: {
        QUOTE: 1,
        INVOICED: 2,
        PAID: 3,
        COMPLETED: 4,
        getCompleted: () => {
            return [2, 3, 4];
        },
    },
    // These should remain in constants:
    TRUE_VALUES: ['true', true, '1', 1],
    CREDIT_QUANTITY: 1,
    ADMIN_FEE_QUANTITY: 1,
    // I'm not sure about this one:
    PURCHASE_TYPE_BELONGS_TO: {
        user: () => {
            return ['top_up_points'];
        },
        org: () => {
            return [
                'points_purchase',
                'licences',
                'licences_renew',
                'licences_addition',
                'awards',
            ];
        },
    },
};
