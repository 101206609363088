"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    'default-messages/customizations/5': `It’s already been {{numOfYears}} years since you started working with us!
  We’d like to take the opportunity to thank you for being such a valuable member of our team.
  We’re truly amazed by your loyalty, dedication, and contribution to our company.
  May you be working (and having fun) with us for many more years to come!`,
    'default-messages/customizations/4': `It’s already been {{numOfYears}} years since you started working with us!
  We’d like to take the opportunity to thank you for being such a valuable member of our team.
  We’re truly amazed by your loyalty, dedication, and contribution to our company.
  May you be working (and having fun) with us for many more years to come!`,
    'default-messages/customizations/3': 'You’ve been with us for {{YEAR}} now. We are proud to have an employee like you as part of our team. Heartfelt congratulations and best wishes on your work anniversary.',
    'default-messages/customizations/2': `Since it’s your special day, we’d like to take this time to send you our warmest wishes. Thanks for being such a great member of our team!

  We’re looking forward to spending many more years in your company. Knowing you, they’ll surely be filled with numerous accomplishments! You can count on us to celebrate them together. `,
    'default-messages/customizations/1': 'Everyone deserves to have a special day at least once a year. Today is your day! Hope your birthday is as wonderful and extraordinary as you are.',
    'default-messages/customizations/7': '{{sender.first_name}} has just marked your goal as “achieved”! Good job!',
    'default-messages/customizations/11': '{{sender.first_name}} has just marked your goal as “partially achieved”! Good job!',
    'default-messages/customizations/10': 'Congratulations! You have partially completed your goal.',
    'default-messages/customizations/6': 'Congratulations! You have successfully completed your goal.',
    'default-messages/customizations/8': `Congratulations {{first_name}}!

  You’re celebrating your {{milestone}} year Anniversary with us, and we’d like to take this opportunity to thank you for being a valuable member of our team.

  To thank you for your loyalty, and dedication, here is a gift voucher you can redeem on the Applauz Marketplace. Just click on the link below to shop for your gift!`,
    'default-messages/customizations/9': '{{company}} is inviting you to join their Employee Recognition Program where your hard work and milestones can be recognized by your peers and rewarded by your managers.',
    'default-messages/customizations/spot-gift': `To thank you for your loyalty, and dedication, here is a gift voucher you can redeem on the Applauz Marketplace. Just click on the link below to shop for your gift!`,
    'default-messages/customizations/12': `We want to express our deepest gratitude for your years of dedication and hard work. Your contributions have made a significant impact on our team and company.

  As you embark on this new journey, we wish you all the best for a happy and fulfilling retirement. Enjoy this well-deserved time to relax and pursue your passions!`,
};
