"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemConfig = void 0;
exports.SystemConfig = {
    active: 1,
    awardsPaidActivatedStatusId: 2,
    customMessageEntityId: 2,
    customMessageTypeId: 8,
    defaultProfileDirectory: 'default_employee_photos/',
    disable: 0,
    leaderBoardsNumberOfDays: 30,
    licensePriceCurrencyCode: 'USD',
    maxPointsAwardsPerRecognition: 25000,
    minPerkPoints: 10,
    onPurchase: 1,
    pbOrganizationEntityId: 2,
    pbUserEntityId: [3, 4],
    topUpPricePerPoint: 0.1,
    voucherType: { milestoneTypeId: 1, spotGiftTypeId: 2 },
};
