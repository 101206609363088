"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwardStatusToServiceAwardStatus = void 0;
const enums_1 = require("@applauz/enums");
exports.AwardStatusToServiceAwardStatus = {
    [enums_1.AwardStatus.UNPAID]: enums_1.ServiceAwardStatus.NOT_PAID,
    [enums_1.AwardStatus.PAID]: enums_1.ServiceAwardStatus.SCHEDULED,
    [enums_1.AwardStatus.ACTIVE]: enums_1.ServiceAwardStatus.SENT,
    [enums_1.AwardStatus.REDEEMED]: enums_1.ServiceAwardStatus.REDEEMED,
    [enums_1.AwardStatus.CREDITED]: enums_1.ServiceAwardStatus.CREDITED,
    [enums_1.AwardStatus.DISABLED]: enums_1.ServiceAwardStatus.CANCELLED,
    [enums_1.AwardStatus.CONVERTED]: enums_1.ServiceAwardStatus.CONVERTED,
};
