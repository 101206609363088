"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoalStatusIdToKeyMap = void 0;
const enums_1 = require("../../enums");
exports.GoalStatusIdToKeyMap = {
    [enums_1.GoalStatus.ONGOING]: 'ongoing',
    [enums_1.GoalStatus.COMPLETE]: 'complete',
    [enums_1.GoalStatus.PARTIAL]: 'partial',
    [enums_1.GoalStatus.MISSED]: 'missed',
};
